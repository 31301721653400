import React, { ReactNode, useEffect, useRef } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import './FullscreenHero.scss';

type FullscreenHeroProps = {
  image: IGatsbyImageData;
  children: ReactNode;
  variant?: 'regular' | 'paralax';
};

const FullscreenHero = ({ image, children, variant = 'paralax' }: FullscreenHeroProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapper = document.querySelectorAll('body')[0];

    if (variant === 'paralax') {
      wrapper?.classList.add('paralax-wrapper');
    }

    return () => {
      wrapper?.classList.remove('paralax-wrapper');
    };
  }, []);

  return (
    <div className={`fullscreen-hero fullscreen-hero--${variant}`}>
      <BgImage
        image={image}
        backgroundColor="#daa744"
        className="fullscreen-hero__bg"
        style={{ height: '100%' }}
        critical
      />
      <div className="fullscreen-hero__content" ref={contentRef}>
        {children}
      </div>
      <span className="fullscreen-hero__scroll" aria-hidden="true"></span>
    </div>
  );
};

export default FullscreenHero;
